import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, combineLatest, iif, of } from 'rxjs';
import { SettingsApiService } from './settings-api.service';
import { SettingsDataInterface } from '../models/settings-data.interface';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ServiceDestroyed, TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NumberHelpersService } from '../../core/helpers/number-helpers.service';
import { WarehouseService } from '../../core/services/warehouse.service';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
@TakeUntilDestroy
export class SettingsService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  defaultSettingsData: SettingsDataInterface = null;

  selectedWarehouse = new FormControl(this.warehouseService.warehouseId);

  settingsForm = this.formBuilder.group({
    innoship_id: [null, Validators.required],
    bliskapaczka_api_access_token: [null, Validators.required],
    company_account_for_cash_on_delivery: [null],
    company_name_contact_for_courier: [null, Validators.required],
    email_contact_for_courier: [null, Validators.required],
    first_name_contact_for_courier: [null],
    last_name_contact_for_courier: [null],
    phone_contact_for_courier: [null, Validators.required],
    user_autologout: [0, Validators.required],
    autologout: [false],
    minimal_sharing_picking_amount: [null, Validators.required],
    collecting_minimal_quantity_popup: [null, Validators.required],
    allow_collecting_from_masterpallet: [false],
    max_delivery_quantity_per_product: [null, Validators.required],
    auto_close_delivery: [false],
    fallback_location: [null, Validators.required],
    cart_location: [null, Validators.required],
    packed_location: [null, Validators.required],
    packing_location: [null, Validators.required],
    damaged_location: [null, Validators.required],
    floor_location: [null, Validators.required],
    masterpallet_number_length: [null, Validators.required],
    minimal_amount_packing_product: [null, Validators.required],
    packing_minimal_quantity_popup: [null, Validators.required],
    bliskapaczka_timeout_on_fetch_waybill: [null, Validators.required],
    order_photos_enabled_retail: [false],
    order_photos_enabled_wholesale: [false],
    short_date_months: [null, Validators.required],
    short_expiry_date_logic: [null, Validators.required],
    damaged_products_location: [null, Validators.required],
    inventory_check_level: [null, Validators.required],
    max_products_transferred_between_inventory_checks: [null, Validators.required],
    inventory_check_max_interval: [null, Validators.required],
    max_inventory_checks_per_day: [null, Validators.required],
    default_minimal_amount_for_replenishment: [null, Validators.required],
    replenishment_location: [null, Validators.required],
    return_location: [null, Validators.required],
    packing_package_minimal_quantity_popup: [null, Validators.required],
    sticker_minimal_quantity_popup: [null, Validators.required],
    transfer_request_minimal_quantity_popup: [null, Validators.required],
    inventory_check_quantity_masterpallet: [null, Validators.required],
    max_products_transferred_between_inventory_checks_masterpallet: [null, Validators.required],
    inventory_check_max_interval_masterpallet: [null, Validators.required],
    max_inventory_checks_per_day_masterpallet: [null, Validators.required],
    user_feedback: [false],
    default_warehouse_id: [null],
    record_activity: [false],
    smartlook_key: [null],
  });

  constructor(
    private settingsApiService: SettingsApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private numberHelpers: NumberHelpersService,
    private warehouseService: WarehouseService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnDestroy(): void {}

  setSettings(settings: object): Observable<void> {
    const mappedSettings: SettingsDataInterface = {};
    Object.entries(settings).forEach(([key, value]) => (mappedSettings[key] = value.toString()));

    return iif(
      // @ts-ignore
      () => this.selectedWarehouse.value === 'global',
      this.settingsApiService.setGlobalSettings(mappedSettings),
      this.settingsApiService.setWarehouseSettings(mappedSettings, this.selectedWarehouse.value),
    ).pipe(
      tap(() => this.toastr.success('Settings saved')),
      takeUntil(this.serviceDestroyed),
    );
  }

  getSettings(): Observable<SettingsDataInterface> {
    this.settingsForm.reset();
    // @ts-ignore
    return combineLatest(
      this.settingsApiService.getGlobalSettings(),
      // @ts-ignore
      this.selectedWarehouse.value !== 'global'
        ? this.settingsApiService.getWarehouseSettings(this.selectedWarehouse.value)
        : EMPTY,
    ).pipe(
      tap(([globalSettings, settings]) => {
        // @ts-ignore
        this.settingsForm.patchValue({
          ...settings,
          autologout: parseInt(settings?.user_autologout, 10) > 0,
          allow_collecting_from_masterpallet: this.numberHelpers.convertNumberToBoolean(
            parseInt(settings.allow_collecting_from_masterpallet as string, 10),
          ),
          auto_close_delivery: this.numberHelpers.convertNumberToBoolean(
            parseInt(settings.auto_close_delivery as string, 10),
          ),
          order_photos_enabled_retail: this.numberHelpers.convertNumberToBoolean(
            parseInt(settings.order_photos_enabled_retail as string, 10),
          ),
          order_photos_enabled_wholesale: this.numberHelpers.convertNumberToBoolean(
            parseInt(settings.order_photos_enabled_wholesale as string, 10),
          ),
          user_feedback: this.numberHelpers.convertNumberToBoolean(settings.user_feedback),
          record_activity: this.numberHelpers.convertNumberToBoolean(settings.record_activity),
        });

        Object.entries(this.settingsForm.value)?.forEach(([key, value]) => {
          if (!value && value !== false && value !== 'false' && value !== 0 && value !== '0') {
            this.settingsForm.get(key).setValue(globalSettings[key]);
          }
        });

        const { autologout } = this.settingsForm.value;
        if (!autologout) this.settingsForm.get('user_autologout').disable();
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  getDefaultSettings(): Observable<SettingsDataInterface> {
    return this.settingsApiService.getDefaultSettings().pipe(
      tap(settings => (this.defaultSettingsData = settings)),
      takeUntil(this.serviceDestroyed),
    );
  }

  getSettingValue(key: string): Observable<string> {
    const cachedSettings = this.localStorageService.getItem('settings');
    if (cachedSettings) return of(cachedSettings[key]);

    return this.settingsApiService.getPublicSettings(this.warehouseService.warehouseId).pipe(
      tap(settings => this.localStorageService.setItem('settings', settings)),
      map(settings => settings[key]),
    );
  }

  getSelectedWarehouse(): number {
    // @ts-ignore
    return this.selectedWarehouse.value !== 'global' ? this.selectedWarehouse.value : null;
  }

  getLocationValue(key: string): number {
    const value = parseInt(
      typeof this.settingsForm.value[key] === 'object' &&
        !Array.isArray(this.settingsForm.value[key]) &&
        this.settingsForm.value[key] !== null
        ? this.settingsForm.value[key]?.id
        : this.settingsForm.value[key],
      10,
    );

    return !Number.isNaN(value) ? value : 0;
  }
}
