import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import PullToRefresh from 'pulltorefreshjs';
import { NavigationEnd, Router } from '@angular/router';
import { AuthorizationService } from './security/services/authorization.service';
import { BarcodeInputService } from './utils/barcodeInput/barcodeInput.service';
import { DocumentEventsService } from './core/services/document-events.service';
import { MenuService } from './miku-menu/services/menu.service';
import { LogTimeService } from './core/services/logTime.service';
import { FiltersService } from './utils/filters/services/filters.service';
import { CorrelationService } from './core/services/correlation.service';
import { NewVersionService } from './core/services/new-version.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { PusherService } from './core/services/pusher.service';
import { versionInfo } from '../../version-info';
import { EveryDayLogoutService } from './core/services/every-day-logout.service';
import { ModulesService } from './core/helpers/modules.service';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { StayOnPageModalComponent } from './utils/modals/stay-on-page-modal/stay-on-page-modal.component';
import { BroadcastService } from './core/services/broadcast.service';
import { BrowserFocusDetectorService } from './core/services/browser-focus-detector.service';
import { PageRefreshDetectorService } from './core/services/page-refresh-detector.service';
import { SessionAnalyzerService } from './core/services/session-analyzer.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('nav') sideNav: MatSidenav;

  sideNavMode$: Observable<MatDrawerMode>;

  versionInfo = versionInfo;

  private selectionRange: Selection;

  private currentUrl: string;

  private previousUrl = '/';

  constructor(
    public authorizationService: AuthorizationService,
    public barcodeInputService: BarcodeInputService,
    private documentEventsService: DocumentEventsService,
    private menuService: MenuService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private correlationService: CorrelationService,
    public filtersService: FiltersService,
    private logTimeService: LogTimeService,
    private newVersionService: NewVersionService,
    private localStorageService: LocalStorageService,
    private pusherService: PusherService,
    private everyDayLogoutService: EveryDayLogoutService,
    private modulesService: ModulesService,
    private dialog: MatDialog,
    private broadcastService: BroadcastService,
    private browserFocusDetectorService: BrowserFocusDetectorService,
    private pageRefreshDetectorService: PageRefreshDetectorService,
    private sessionAnalyzerService: SessionAnalyzerService,
  ) {
    router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
            this.browserFocusDetectorService.currentUrl = this.currentUrl;
            const currentModule = this.modulesService.getModuleByPath(this.currentUrl) ?? null;
            const previousModule = this.modulesService.getModuleByPath(this.previousUrl) ?? null;

            if (!currentModule || !previousModule?.includes(currentModule)) {
              this.logTimeService.logTime(this.previousUrl, this.currentUrl);
            }

            this.previousUrl = this.currentUrl;
          }
        }),
      )
      .subscribe();

    this.browserFocusDetectorService.observe();
    this.pageRefreshDetectorService.observe();
  }

  static isFormElement(element: Element): boolean {
    return element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA' || element.nodeName === 'SELECT';
  }

  ngOnInit() {
    window.addEventListener('beforeunload', e => {
      if (environment.production && this.authorizationService.isUserLoggedIn()) {
        console.log('beforeunload');
        this.pusherService.unsubscribeAll();
        this.pusherService.pusher.disconnect();
        this.sessionAnalyzerService.disconnect();
        this.dialog.open(StayOnPageModalComponent, {
          width: '300px',
        });
        e.preventDefault();
        e.returnValue = 'Are you sure?';
      }
    });

    const { router } = this;
    PullToRefresh.init({
      mainElement: '.mat-toolbar',
      triggerElement: '.mat-toolbar',
      onRefresh: () => {
        router.routeReuseStrategy.shouldReuseRoute = () => false;
        router.onSameUrlNavigation = 'reload';
        router.navigate([router.url]).then(r => r);
      },
    });

    this.sideNavMode$ = this.breakpointObserver
      .observe('(max-width: 1024px)')
      .pipe(map(v => (v.matches ? 'over' : 'side')));

    if (this.authorizationService.isUserLoggedIn()) {
      this.pusherService.connect();
      if (this.localStorageService.getItem('autologoutTime') > 60) this.authorizationService.idleUserLogoutTimer();
      this.pusherService.unsubscribe(this.localStorageService.getItem('connectedChannel'));
      this.broadcastService.connect();
      this.sessionAnalyzerService.init();
    }

    this.documentEventsService.selectionChange$
      .pipe(
        tap(v => {
          this.selectionRange = v;
        }),
      )
      .subscribe();

    this.menuService
      .shouldCloseMenu$()
      .pipe(
        tap((v: boolean) => {
          if (v) this.sideNav.close();
        }),
      )
      .subscribe();

    this.menuService
      .closeMenu$()
      .pipe(
        tap(() => {
          this.sideNav?.close();
        }),
      )
      .subscribe();

    this.everyDayLogoutService.init();
  }

  ngAfterViewInit() {
    if (!this.breakpointObserver.isMatched('(max-width: 1024px)')) {
      const navBarStatus = !!+this.localStorageService.getItem('navBarOpen');
      const operation = navBarStatus ? 'open' : 'close';
      setTimeout(() => this.sideNav[operation](), 0);
    }
  }

  ngOnDestroy() {
    PullToRefresh.destroyAll();
    if (this.authorizationService.idleTime) this.authorizationService.idleTime?.cleanUp();
  }

  focusOnBarcode(event): void {
    if ((event.target && AppComponent.isFormElement(event.target)) || this.selectionRange?.type === 'Range') {
      return;
    }

    this.barcodeInputService.focusScanInput();
  }

  navOpenChanges(status: boolean) {
    if (!this.breakpointObserver.isMatched('(max-width: 1024px)')) {
      this.localStorageService.setItem('navBarOpen', (status ? 1 : 0).toString());
    }
  }

  changeOfRoutes() {
    this.filtersService.changeMobileFiltersBtnState(false);
  }

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  click(event) {
    if (event.target.tagName === 'BUTTON' || event.target.tagName === 'TD' || event.target.tagName === 'A') {
      this.correlationService.generateId();
    }
  }
}
