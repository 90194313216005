import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-tile',
  templateUrl: './date-tile.component.html',
  styleUrls: ['./date-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTileComponent implements OnInit {
  @Input() value: number;
  @Input() active: number;
  @Input() description: string;
  @Output() valueChanges: EventEmitter<number> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  public changeValue() {
    this.valueChanges.emit(this.value);
  }
}
