import { Component, OnInit } from '@angular/core';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-mobile-filters-btn',
  templateUrl: './mobile-filters-btn.component.html',
  styleUrls: ['./mobile-filters-btn.component.scss'],
})
export class MobileFiltersBtnComponent {
  constructor(private filtersService: FiltersService) {}

  changeMobileFiltersState(): void {
    this.filtersService.changeMobileFiltersState();
  }
}
