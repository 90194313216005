export enum OrderStatusEnum {
  NEW = 'new',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  READY_TO_COLLECT = 'ready-to-collect',
  COLLECTING_IN_PROGRESS = 'collecting-in-progress',
  COLLECTED = 'collected',
  PACKING_IN_PROGRESS = 'packing-in-progress',
  PACKED = 'packed',
  READY_TO_DISPATCH = 'ready-to-dispatch',
  DISPATCHED = 'dispatched',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}
