import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StringReplacePipe } from './pipes/string-replace.pipe';
import { LinkDirective } from './directives/link.directive';
import { SelectClickableDirective } from './directives/select-clickable.directive';
import { HasAccessDirective } from './directives/has-access.directive';
import { TextWrapPipe } from './pipes/text-wrap.pipe';
import { CheckValueNaPipe } from './pipes/check-value-na.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { UpperFirstLetterPipe } from './pipes/upper-first-letter.pipe';
import { AdDirective } from './directives/ad.directive';
import { CheckShortDateDirective } from './directives/check-short-date.directive';
import { SearchPipe } from './pipes/search.pipe';
import { PriceFormatPipe } from './pipes/price-format.pipe';
import { ConvertSecondsToMinutesPipe } from './pipes/convert-seconds-to-minutes.pipe';
import { FormControlPipe } from './pipes/form-control.pipe';
import { ConvertWeightPipe } from './pipes/convert-weight.pipe';
import { AddSpaceBetweenCharPipe } from './pipes/add-space-between-char.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    LinkDirective,
    SelectClickableDirective,
    StringReplacePipe,
    HasAccessDirective,
    TextWrapPipe,
    CheckValueNaPipe,
    DateFormatPipe,
    UpperFirstLetterPipe,
    AdDirective,
    CheckShortDateDirective,
    SearchPipe,
    PriceFormatPipe,
    ConvertSecondsToMinutesPipe,
    FormControlPipe,
    ConvertWeightPipe,
    AddSpaceBetweenCharPipe,
  ],
  exports: [
    LinkDirective,
    SelectClickableDirective,
    StringReplacePipe,
    HasAccessDirective,
    TextWrapPipe,
    CheckValueNaPipe,
    DateFormatPipe,
    UpperFirstLetterPipe,
    AdDirective,
    CheckShortDateDirective,
    SearchPipe,
    PriceFormatPipe,
    ConvertSecondsToMinutesPipe,
    ConvertWeightPipe,
    AddSpaceBetweenCharPipe,
  ],
})
export class CoreModule {}
