import { Inject, Injectable, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { Filters } from '../../../core/services/list/models/Filters';
import { Order } from '../../../core/services/list/models/Order';
import { SortOption } from '../../../core/services/list/models/SortOption';
import { Paginator } from '../../../core/services/list/models/Paginator';
import { FieldTypesEnum } from '../../../core/services/list/models/field-types.enum';
import { TextFilterField } from '../../../core/services/list/models/TextFilterField';
import { FilterField } from '../../../core/services/list/models/FilterField';
import { NumberFilterField } from '../../../core/services/list/models/NumberFilterField';
import { BooleanFilterField } from '../../../core/services/list/models/BooleanFilterField';
import { SelectFilterField } from '../../../core/services/list/models/SelectFilterField';
import { RangeNumberFilterField } from '../../../core/services/list/models/RangeNumberFilterField';
import { RangeDateFilterField } from '../../../core/services/list/models/RangeDateFilterField';
import { DefaultComponentFilter } from '../../../core/services/list/models/DefaultComponentFilter';
import { BehaviorFilters } from '../models/BehaviorFilters.model';
import { ServiceDestroyed, TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { List } from '../../../core/models/list';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { AutocompleteFilterField } from '../../../core/services/list/models/AutocompleteFilterField';
import { MultiselectFilterField } from '../../../core/services/list/models/MultiselectFilterField';

@Injectable({
  providedIn: 'root',
})
@TakeUntilDestroy
export class FiltersService implements ServiceDestroyed, OnDestroy {
  serviceDestroyed = new Subject<void>();

  showMobileFilters = false;

  mobileFiltersBtn$ = new BehaviorSubject<boolean>(false);

  private filters$ = new BehaviorSubject<BehaviorFilters>({ filters: null, saveFilters: false });

  private _filters: Filters;

  private _currentComponentName: string;

  private _paginator: MatPaginator;

  private clearFunction = () => {};

  constructor(
    @Inject('REFRESH_LIST_SUBJECT') private _refreshSubject: Subject<void>,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnDestroy() {}

  get mobileFiltersBtn(): Observable<boolean> {
    return this.mobileFiltersBtn$.asObservable();
  }

  get filters(): Filters {
    return this._filters;
  }

  set filters(filters: Filters) {
    this._filters = filters;
  }

  set totalResultsLength(value: number) {
    this._filters.paginator.resultsLength = value;
  }

  get paginator(): MatPaginator {
    return this._paginator;
  }

  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
  }

  get currentComponentName() {
    return this._currentComponentName;
  }

  set currentComponentName(name: string) {
    this._currentComponentName = name;
  }

  get filtersObservable(): Observable<BehaviorFilters> {
    return this.filters$.asObservable();
  }

  get refreshSubject(): Subject<void> {
    return this._refreshSubject;
  }

  emitRefreshSubject(): void {
    this._refreshSubject.next();
  }

  setFilters(filters: Filters): void {
    this.filters$.next({ filters, saveFilters: false });
  }

  init(subject: (initializedFilters: Filters) => unknown, filters: Filters = null): Observable<any> {
    this._refreshSubject = new Subject();

    this.filters = filters ?? new Filters(null, null, new Paginator(1, 25));

    this.setFilters(filters);

    this.changeMobileFiltersBtnState(true);

    return this._refreshSubject.pipe(
      switchMap(() => this.subscribeInitSubject(subject)),
      takeUntil(this.serviceDestroyed),
    );
  }

  subscribeInitSubject(subject: (initializedFilters: Filters) => any): Observable<unknown> {
    return subject(this.filters).pipe(
      tap(({ meta }: List<any>) => {
        if (meta && meta.pagination) {
          this.totalResultsLength = meta.pagination?.total;
        } else if (meta && meta.total) {
          this.totalResultsLength = meta.total;
        }
      }),
    );
  }

  clearValues(): void {
    this.localStorageService.removeItem(`Filters${this.currentComponentName}`);
    const {
      value: { filters },
    } = this.filters$;
    this.clearFunction();
    filters.fields.forEach(el => (el.defaultValue !== true ? el.clear() : null));
  }

  saveFilters(filters: Filters): void {
    const { fields, order, paginator } = filters;
    const mapFilters = { fields: [], order: {}, paginator: {} };

    mapFilters.fields = fields.map(field => {
      const { condition, key, label, type, uuid, valueControl, visible, fromValue, toValue, clause, asParameter } =
        field;

      const map = {
        condition,
        key,
        label,
        type,
        uuid,
        valueControl: { value: valueControl?.value },
        visible,
        availableOptions$: [],
        fromValue: {
          value: fromValue?.value ?? null,
        },
        toValue: { value: toValue?.value ?? null },
        dateFrom: null,
        dateTo: null,
        clause,
        asParameter,
      };

      switch (field.type) {
        case FieldTypesEnum.SELECT:
          break;

        case FieldTypesEnum.AUTOCOMPLETE:
          break;

        case FieldTypesEnum.RANGE_NUMBER:
          map.fromValue.value = fromValue?.value;
          map.toValue.value = toValue?.value;
          break;

        case FieldTypesEnum.RANGE_DATEPICKER:
          if (field.fromValue?.value && field.fromValue?.value) {
            map.dateFrom = new Date(field.fromValue?.value);
            map.dateTo = new Date(field.toValue?.value);
          }
          break;

        default:
          break;
      }

      return map;
    });
    mapFilters.order = {
      key: { value: order.key.value },
      sortDirection: { value: order.sortDirection.value },
      orderFields: order.orderFields.map(({ key, label }) => ({ key, label })),
      sortDirectionOptions: order.sortDirectionOptions,
    };
    mapFilters.paginator = paginator;

    this.localStorageService.setItem(`Filters${this.currentComponentName}`, mapFilters);
  }

  restoreFilters() {
    const savedFilters: Filters = this.localStorageService.getItem<Filters>(`Filters${this.currentComponentName}`);

    if (savedFilters !== null) {
      const { fields, order, paginator } = savedFilters;

      this._filters = new Filters(
        this.prepareFilterFields(fields),
        this.prepareFilterOrder(order),
        this.prepareFilterPaginator(paginator),
      );
      this.setFilters(this._filters);
    }
  }

  prepareFilterFields(fields: FilterField[]) {
    const fieldsArray = [];
    fields.forEach(field => {
      switch (field.type) {
        case FieldTypesEnum.TEXT:
          fieldsArray.push(
            new TextFilterField(field.key, field.label, field.valueControl.value)
              .setCondition(field.condition)
              .patchValue(field.valueControl.value)
              .setVisible(field.visible)
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.NUMBER:
          fieldsArray.push(
            new NumberFilterField(field.key, field.label, field.valueControl.value)
              .setCondition(field.condition)
              .patchValue(field.valueControl.value)
              .setVisible(field.visible)
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.BOOLEAN:
          fieldsArray.push(
            new BooleanFilterField(field.key, field.label, field.valueControl.value)
              .setCondition(field.condition)
              .patchValue(field.valueControl.value)
              .setVisible(field.visible)
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.SELECT:
          fieldsArray.push(
            new SelectFilterField(
              field.key,
              field.label,
              this.getAvailableOptions(field.key, FieldTypesEnum.SELECT),
              field.valueControl.value,
            )
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.MULTISELECT:
          fieldsArray.push(
            new MultiselectFilterField(
              field.key,
              field.label,
              this.getAvailableOptions(field.key, FieldTypesEnum.MULTISELECT),
              field.valueControl.value,
            ).setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.AUTOCOMPLETE:
          fieldsArray.push(
            new AutocompleteFilterField(
              field.key,
              field.label,
              this.getAvailableOptions(field.key, FieldTypesEnum.AUTOCOMPLETE),
              field.valueControl.value,
            )
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.RANGE_NUMBER:
          fieldsArray.push(
            new RangeNumberFilterField(field.key, field.label, field.fromValue.value, field.toValue.value)
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.RANGE_DATEPICKER:
          fieldsArray.push(
            new RangeDateFilterField(field.key, field.label, field.dateFrom, field.dateTo)
              .setClause(field.clause)
              .setAsParameter(field.asParameter),
          );
          break;

        case FieldTypesEnum.DEFAULT_COMPONENT:
          fieldsArray.push(
            new DefaultComponentFilter(field.key, field.label, field.valueControl.value)
              .setClause(field.clause)
              .setIf(field.if)
              .setAsParameter(field.asParameter),
          );
          break;

        default:
          throw new Error(`Invalid fieldType: ${field.type}`);
      }
    });
    return fieldsArray;
  }

  getAvailableOptions(key, type) {
    return this.filters.fields?.find(field => field.key === key && field.type === type)?.availableOptions$;
  }

  prepareFilterOrder({ key: { value: orderKey }, sortDirection: { value: sortDirection }, orderFields }: Order) {
    return new Order(
      [orderKey, sortDirection],
      orderFields.map(field => new SortOption(field.key, field.label)),
    );
  }

  prepareFilterPaginator(paginator: Paginator) {
    const { page, perPage, pageSizeOptions, visible, resultsLength } = paginator;
    return new Paginator(page, perPage, pageSizeOptions, visible, resultsLength);
  }

  runFilters(keepPage?: boolean, isSaveFilters: boolean = false) {
    return this.filtersObservable.pipe(
      filter((behaviorFilters: BehaviorFilters): boolean => {
        const { filters, saveFilters } = behaviorFilters;
        if (isSaveFilters || saveFilters) {
          if (filters) {
            this.saveFilters(filters);
          }
          isSaveFilters = false;
        }

        if (filters) {
          return filters.fields.every((el: FilterField) => el.isValid());
        }
        return true;
      }),
      tap(() => {
        if (!keepPage) {
          this.paginator?.firstPage();
          this.emitRefreshSubject();
        } else {
          this.emitRefreshSubject();
        }
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  changeMobileFiltersState(state: boolean = null): void {
    state !== null ? (this.showMobileFilters = state) : (this.showMobileFilters = !this.showMobileFilters);
    setTimeout(() => {
      const eanInput: HTMLInputElement = document.querySelector('[data-label="EAN"]') ?? null;
      if (eanInput) eanInput.focus();
    }, 200);
  }

  changeMobileFiltersBtnState(state: boolean): void {
    this.mobileFiltersBtn$.next(state);
  }
}
